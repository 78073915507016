import React from "react"
import * as styles from "./privacy-section.module.scss"
import { Container, Row, Col } from "react-bootstrap";
import VerticalSpacingRow from "../../components/vertical-spacing/vertical-spacing";
import VerticalLineRow from "../../components/vertical-line/vertical-line";
import LeftHeaderTextRow from "../../components/left-header-text/left-header-text";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { MeetUsId } from "../../components/layout/layout";

export default function PrivacySection(props) {

  return <>
    <Container fluid>
      {/* <VerticalSpacingRow /> */}
      {/* <VerticalLineRow /> */}
      <VerticalSpacingRow />
      <Row className="no-gutters align-items-center">
        <Col xs={{ span: 12, order: 1 }}>
          <Container fluid>
            <br/>
            <br/>
            <br/>
            {/* todo - check horizontal gutter spacing */}
            <Row>
              <Col xs={12}>
                <LeftHeaderTextRow
                  header={"POLITYKA PRYWATNOŚCI"}
                  text={`
<p>
Niniejsza Polityka Prywatności (dalej: Polityka) jest dokumentem, w którym informujemy Cię w jakim zakresie i w jakim celu administrator twoich danych osobowych gromadzi i przetwarza twoje dane osobowe. Ponadto, Polityka zawiera informacje o plikach cookies wykorzystywanych w Serwisie. 
Polityka ma charakter informacyjny,  w związku z czym nie stanowi źródła praw i obowiązków ani Administratora, ani Użytkowników.
W razie pytań i wątpliwości możesz skontaktować się z nami przy pomocy adresu poczty elektronicznej: kontakt@browarkolo.pl
</p>
<h5>§ 1. Definicje</h5>
<p>
Użyte w Polityce pojęcia oznaczają:
<ol>
<li>Administrator – Monika Grabowska prowadząca działalność gospodarczą pod firmą PRZEDSIĘBIORSTWO WIELOBRANŻOWE "MONIKA" MONIKA GRABOWSKA, Powiercie 9B, 62-600 Powiercie, NIP: 6661868773, REGON: 311557934.</li>
<li>Serwis – niniejsza strona internetowa;</li>
<li>Użytkownik – osoba korzystająca z Serwisu;</li>
</ol>
</p>

<h5>§ 2. Postanowienia ogólne</h5>
<ol>
<li>Administrator przetwarza dane osobowe Użytkownika zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej: RODO) oraz krajowymi przepisami powszechnie obowiązującego prawa dotyczącymi ochrony danych osobowych, oraz ustawą o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r. (Dz.U. 2002 Nr 144, poz. 1204 ze zm.).</li>
<li>
  Administrator dokłada szczególnej staranności w celu ochrony interesów osób, których dane dotyczą, a w szczególności zapewnia, że dane są:
  <ol>
  <li>przetwarzane zgodnie z prawem, rzetelnie i w sposób przejrzysty dla osoby, której dane dotyczą („zgodność z prawem, rzetelność i przejrzystość”);</li>
  <li>zbierane w konkretnych, wyraźnych i prawnie uzasadnionych celach i nieprzetwarzane dalej w sposób niezgodny z tymi celami („ograniczenie celu”);</li>
  <li>adekwatne, stosowne oraz ograniczone do tego, co niezbędne do celów, w których są przetwarzane („minimalizacja danych”);</li>
  <li>prawidłowe i w razie potrzeby uaktualniane; należy podjąć wszelkie rozsądne działania, aby dane osobowe, które są nieprawidłowe w świetle celów ich przetwarzania, zostały niezwłocznie usunięte lub sprostowane („prawidłowość”);</li>
  <li>przechowywane w formie umożliwiającej identyfikację osoby, której dane dotyczą, przez okres nie dłuższy, niż jest to niezbędne do celów, w których dane te są przetwarzane („ograniczenie przechowywania”);</li>
  <li>przetwarzane w sposób zapewniający odpowiednie bezpieczeństwo danych osobowych, w tym ochronę przed niedozwolonym lub niezgodnym z prawem przetwarzaniem oraz przypadkową utratą, zniszczeniem lub uszkodzeniem, za pomocą odpowiednich środków technicznych lub organizacyjnych („integralność i poufność”).</li>
  </ol>
</li>
<li>Administrator nie powołał Inspektora Ochrony Danych.</li>
<li>Serwis oraz usługi Administratora nie są przeznaczone dla osób poniżej 18 roku życia ani do nich skierowane. Administrator świadomie nie gromadzi i nie przetwarza danych osób poniżej 18 roku życia.</li>
</ol>

<h5>§ 3. Sposób gromadzenia danych</h5>

<ol>
<li>	Administrator może gromadzić dane Użytkowników za pomocą następujących sposobów:
  <ol>
  <li>przekazanie ich przez Użytkownika wiadomości przesłanej na adres poczty elektronicznej lub przy pomocy innego środka komunikacji;</li>
  <li>za pośrednictwem plików Cookies, pod warunkiem wyrażenia przez użytkownika zgody na korzystanie z plików Cookies.</li>
  </ol>
</li>
<li>Podawanie jakichkolwiek danych przez Użytkowników jest dobrowolne, jednakże ich niepodanie skutkować może brakiem możliwości:
  <ol>
  <li>odpowiedzi ze strony Administratora;</li>
  <li>zawarcia umowy z Administratorem.</li>
  </ol>
</li>
</ol>

<h5>§ 4. Zakres, cel i sposób przetwarzania danych</h5>
<ol>
<li>Administrator może gromadzić następujące informacje o Użytkownikach:
  <ol>
    <li>imię i nazwisko;</li>
    <li>adres poczty elektronicznej (e-mail);</li>
    <li>adres IP;</li>
    <li>informacje techniczne o jakichkolwiek urządzeniach, z których korzysta Użytkownik, żeby uzyskać dostęp do usług Administratora, w tym: połączenia internetowe i/lub sieciowe, jakikolwiek identyfikator urządzenia mobilnego, system operacyjny, rodzaj przeglądarki lub innego oprogramowania, lub też dane dotyczące sprzętu komputerowego oraz inne informacje techniczne; są to dane techniczne o  Użytkownikach oraz ich działaniach i schematach działań, które nie zawierają danych osobowych;</li>
    <li>wszelkie inne informacje przekazane przez Użytkownika.</li>
  </ol>
</li>
<li>Administrator może przetwarzać gromadzone dane w celu:
  <ol>
    <li>odpowiedzi na wiadomości Użytkownika</li>
    <li>zawarcia umowy zgodnie z wolą Użytkownika;</li>
    <li>wykonania umowy zawartej z Użytkownikiem, w tym świadczenia usług i dochodzenia roszczeń;</li>
    <li>wykonania ciążących na Administratorze obowiązków prawnych (w szczególności: wystawiania faktur, prowadzenia ksiąg rachunkowych, wykonywania wezwań upoważnionych organów państwowych);</li>
    <li>marketingu bezpośredniego, w tym profilowania;</li>
    <li>prowadzenia statystyki;</li>
    <li>wysyłki zamówionej przez Użytkownika informacji handlowej lub oferty;</li>
    <li>wykonania i przechowywania kopii bezpieczeństwa, zapewnienia zdolności do ciągłego zapewnienia poufności, integralności, dostępności i odporności systemów i usług przetwarzania; zapewnienia zdolności do szybkiego przywrócenia dostępności danych osobowych i dostępu do nich w razie incydentu fizycznego lub technicznego;</li>
    <li>realizacji tzw. uzasadnionego interesu w rozumieniu RODO.</li>
  </ol>
</li>
<li>Dane osobowe Użytkownika mogą być przekazywane następującym rodzajom odbiorców:
  <ol>
    <li>pracownikom, współpracownikom, pełnomocnikom i podwykonawcom Administratora;</li>
    <li>podmiotom świadczącym usługi na rzecz Administratora, w szczególności biurom rachunkowym, kancelariom prawnym, doradcom podatkowym, dostawcom usług hostingowych, informatycznych czy programistycznych (w szczególności na potrzeby obsługi Serwisu, poczty elektronicznej i przechowywania danych w chmurze);</li>
    <li>upoważnionym organom państwowym.</li>
  </ol>
<li>Dane osobowe Użytkowników nie będą przekazywane podmiotom trzecim w celach marketingowych.</li>
<li>Administrator przetwarza dane w okresie niezbędnym do realizacji określonego celu, w tym w szczególności:
  <ol>
    <li>w przypadku wykonania umowy – do czasu przedawnienia roszczeń z tytułu umowy;</li>
    <li>w przypadku przetwarzania w celach marketingowych - do wykonania przez Użytkownika praw wyłączających przetwarzanie danych osobowych w celu marketingu bezpośredniego, w tym do momentu złożenia sprzeciwu lub wycofania zgody.</li>
  </ol>
Ponadto, okres przechowywania danych będzie nie krótszy niż wynikający z powszechnie obowiązujących przepisów prawa. 
</li>
</ol>

<h5>
§ 6. Polityka cookies
</h5>
<ol>
<li>Pliki Cookies (ciasteczka) są to niewielkie informacje tekstowe w postaci plików tekstowych, wysyłane przez serwer i zapisywane po stronie osoby odwiedzającej stronę Sklepu Internetowego (np. na dysku twardym komputera, laptopa, czy też na karcie pamięci smartfona – w zależności z jakiego urządzenia korzysta Użytkownik). Szczegółowe informacje dot. plików Cookies można znaleźć m.in. tutaj: http://pl.wikipedia.org/wiki/Ciasteczko.</li>
<li>Pliki Cookies stosowane są do następujących celów: zachowanie bezpieczeństwa usług i zapobiegania oszustwom, ułatwienia wydajności strony, rejestracja wizyt do celów marketingowych i statystycznych, wspieranie personalizacji stron internetowych (np. zapamiętanie informacji, że Użytkownik ma powyżej osiemnastu lat). Pliki Cookies mogą być również wykorzystywane i zamieszczane przez współpracujących z Administratorem partnerów – podlegają wówczas politykom cookie lub politykom prywatności zamieszczających je podmiotów.</li>
<li>Większość popularnych przeglądarek internetowych domyślnie akceptuje zapisywanie plików Cookies w związku z czym brak zmiany ustawień przeglądarki jest równoznaczny z wyrażeniem zgody na korzystanie z plików Cookies. Pod poniższymi linkami znajdują się informacje dotyczące ustawień Cookies w najpopularniejszych przeglądarkach:
  <ul>
    <li>Google Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=pl">https://support.google.com/chrome/answer/95647?hl=pl</a> </li>
    <li>Mozilla Firefox: <a href="https://support.mozilla.org/pl-PL/kb/cookies-information-websites-store-on-your-computer">https://support.mozilla.org/pl-PL/kb/cookies-information-websites-store-on-your-computer</a> </li>
    <li>Safari: <a href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac">https://support.apple.com/pl-pl/guide/safari/sfri11471/mac</a> </li>
    <li>Microsoft Edge: <a href="https://support.microsoft.com/pl-pl/microsoft-edge/usuwanie-plik%C3%B3w-cookie-w-przegl%C4%85darce-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">https://support.microsoft.com/pl-pl/microsoft-edge/usuwanie-plik%C3%B3w-cookie-w-przegl%C4%85darce-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09</a> </li>
    <li>Internet Explorer: <a href="https://support.microsoft.com/pl-pl/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/pl-pl/help/17442/windows-internet-explorer-delete-manage-cookies</a> </li>
  </ul>
  </li>
  <li>Administrator informuje, że wyłączenie lub ograniczenia plików Cookies może powodować trudności lub brak możliwości korzystania z Serwisu.</li>
</ol>

<h5>
§ 7. Prawa osób, których dotyczą dane
</h5>
<ol>
<li>Osoba, której dane dotyczą, jest uprawniona do uzyskania od administratora potwierdzenia, czy przetwarzane są dane osobowe jej dotyczące, a jeżeli ma to miejsce, jest uprawniona do uzyskania dostępu do nich oraz informacji podanych w tym przepisie – art. 15 RODO.</li>
<li>Osoba, której dane dotyczą, ma prawo żądania od administratora niezwłocznego sprostowania dotyczących jej danych osobowych, które są nieprawidłowe. Z uwzględnieniem celów przetwarzania, osoba, której dane dotyczą, ma prawo żądania uzupełnienia niekompletnych danych osobowych, w tym poprzez przedstawienie dodatkowego oświadczenia – art. 16 RODO.</li>
<li>Osoba, której dane dotyczą, ma prawo żądania od administratora niezwłocznego usunięcia dotyczących jej danych osobowych, a administrator ma obowiązek bez zbędnej zwłoki usunąć dane osobowe, jeżeli zachodzi jedna z okoliczności wskazanych w tym przepisie – art. 17 RODO.</li>
<li>Ograniczenie przetwarzania oznacza oznaczenie przechowywanych danych osobowych w celu ograniczenia ich przyszłego przetwarzania. Po takim oznaczeniu danych ich przetwarzanie, poza przechowywaniem, jest możliwe tylko na podstawie zgody lub w celach określonych tym przepisem. Ograniczenia można żądać w przypadkach określonych w tym przepisie – art. 18 RODO.</li>
<li>Osoba, której dane dotyczą, ma prawo otrzymać w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego dane osobowe jej dotyczące, które dostarczyła administratorowi, oraz ma prawo przesłać te dane osobowe innemu administratorowi bez przeszkód ze strony administratora – art. 20 RODO.</li>
<li>W przypadku, gdy Administrator zamierza przetwarzać lub przetwarza dane Użytkownika w celu marketingu bezpośredniego, osoba której dane dotyczą uprawniona jest w dowolnym momencie wnieść sprzeciw wobec przetwarzania dotyczących jej danych osobowych, w tym również w zakresie profilowania.</li>
<li>Jeżeli Administrator przetwarza dane osobowe Użytkownika na podstawie zgody, zgoda może zostać wycofana w każdym czasie, bez wpływu na zgodność z prawem przetwarzania dokonanego przed wycofaniem zgody.</li>
</ol>

<h5>
§ 8. Postanowienia końcowe
</h5>
<ol>
<li>Polityka dotyczy tylko niniejszego Serwisu. Administrator informuje, że w Serwisie mogą znaleźć się odnośniki do innych stron czy też odtwarzacze multimedialne (np. Instagram i Facebook), które nie są prowadzone przez Administratora. Administrator zachęca do zapoznania się z polityką prywatności i polityką cookies po przejściu na strony innych podmiotów.</li>
<li>Administrator stosuje środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych osobowych odpowiednią do zagrożeń oraz kategorii danych objętych ochroną, a w szczególności zabezpiecza dane przed ich udostępnieniem osobom nieupoważnionym, zabraniem przez osobę nieuprawnioną, przetwarzaniem z naruszeniem obowiązujących przepisów oraz zmianą, utratą, uszkodzeniem lub zniszczeniem.</li>
<li>Administrator udostępnia środki techniczne zapobiegające pozyskiwaniu i modyfikowaniu przez osoby nieuprawnione danych osobowych, przesyłanych drogą elektroniczną.</li>
<li>Administrator może okresowo aktualizować niniejszą Politykę.</li>
<li>W przypadku uznania, że dane osobowe przetwarzane są niezgodnie z przepisami prawa, Użytkownikowi przysługuje prawo złożenia skargi do Prezesa Urzędu Ochrony Danych Osobowych. Więcej informacji znajduje się tu: <a href="https://uodo.gov.pl/pl/83/155">https://uodo.gov.pl/pl/83/155</a> </li>
</ol>

                  `}
                />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <VerticalSpacingRow />
      {/* <VerticalLineRow /> */}
    </Container>
  </>


}