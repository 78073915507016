import React from "react";
import Layout from "../components/layout/layout";
import PrivacySection from "../page-sections/privacy-section/privacy-section";

export default function Home() {
  return <>
    <Layout>
        <PrivacySection />
    </Layout>
  </>
}
